//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { AUTH_MODE_DEVELOPMENT, REDIRECT_URLS } from '~/constants/auth'
import { getErrorStatusCode } from '~/utils/helpers'
import { CLIENT_TIMEZONE } from '~/constants'

export default defineComponent({
    name: 'CoSignupForm',
    props: {
        page: {
            type: String,
            default: 'index'
        },
        savedRequest: {
            type: String,
            default: null
        },
        googleAuthBtnWidth: {
            type: Number,
            default: 304
        },
        isLoginAndSignupLayout: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        name: '',
        login: '',
        password: '',
        captcha: '',
        isNeedCaptcha: false,
        hideErrorsTimeout: null,
        errors: {
            name: '',
            login: '',
            password: ''
        },
        loading: false,
        isLoginBtn: false
    }),
    fetchOnServer: false,
    async fetch () {
        const captcha = await this.$api.get('guests/auth/captcha')
            .catch(err => this.$handleErrors(err))
        if (!captcha) { return {} }
        this.isNeedCaptcha = captcha.data.is_need
    },
    computed: {
        ...mapGetters({
            isMobile: 'isMobile',
            promo: 'cookies/promo',
            fullUrl: 'cookies/fullUrl',
            tsource: 'cookies/tsource'
        }),
        isIndexPage () {
            return this.page === 'index'
        }
    },
    methods: {
        setVRememberValue () {
            localStorage.setItem('savedInputsValue', JSON.stringify({ login: this.login }))
        },
        submit () {
            if (this.localValidate()) {
                this.loading = true
                // Перед сабмитом нужно спрятать капчу, если в ней поставили галочку (this.captcha=true).
                // Это необходимо для того, чтобы после сабмита капчи уже не было. Если нужно будет, то в .catch() ее включат снова
                if (this.captcha) {
                    this.isNeedCaptcha = false
                }
                this.$api.post('guests/auth/signup', {
                    name: this.name,
                    login: this.login,
                    password: this.password,
                    fp_token2: this.$fingerprint,
                    captcha: this.captcha,
                    timezone: CLIENT_TIMEZONE,
                    promo_uid: this.promo.uid,
                    promo_sid: this.promo.sid,
                    promo_url: this.promo.uid && this.fullUrl.uid === this.promo.uid ? this.fullUrl.referrer : '',
                    tsource_uid: this.tsource.uid,
                    tsource_optin: this.tsource.optin,
                    tsource_date: this.tsource.date,
                    saved_request: this.$route.params?.savedRequest
                })
                    .then((res) => {
                        if (this.$config.authMode === AUTH_MODE_DEVELOPMENT) {
                            this.$store.dispatch('cookies/setAuthCookie', {
                                id: res.data.id,
                                email: res.data.login,
                                token: res.data.token,
                                fingerprint: res.data.fp_token2
                            })
                        }
                        // remove tsource cookie
                        this.$store.dispatch('cookies/removeTsourceCookie')
                        // select redirect url
                        const serverRedirect = res.data.redirect && REDIRECT_URLS[res.data.redirect]
                            ? REDIRECT_URLS[res.data.redirect]
                            : REDIRECT_URLS.mypage
                        // change '/prime' redirect to '/oto' for new user's signup
                        const clientRedirect = this.$route.params?.redirect === '/prime'
                            ? REDIRECT_URLS.offer
                            : this.$route.params?.redirect
                        window.location.href = clientRedirect || serverRedirect
                    })
                    .catch(async (err) => {
                        if (getErrorStatusCode(err) === 422) {
                            let hasFieldError = false
                            let hasCaptchaError = false
                            const errors = err.response?.data?.errors || {}
                            for (const key in errors) {
                                if (key === 'captcha') {
                                    hasCaptchaError = true
                                }
                                if (this.errors[key] !== undefined) {
                                    this.errors[key] = errors[key][0]
                                    hasFieldError = true

                                    if (key === 'login' && this.errors[key] === 'Email has already been taken') {
                                        this.isLoginBtn = true
                                    }
                                }
                            }

                            // Если капчи не было (например, была убрана перед сабмитом или вообще ее еще не было), то нужно
                            // её вывести, избежав вывод текста ошибки "Captcha required" в this.$alert(message) ниже в else if.
                            // А если капча уже была (например без галки в момент старта сабмита), то выведется ошибка
                            // this.$alert(message) что "Captcha required" (отработает else if)
                            if (hasCaptchaError && !this.isNeedCaptcha) {
                                this.isNeedCaptcha = true
                            } else if (!hasFieldError) {
                                const message = errors && errors[Object.keys(errors)[0]] && Array.isArray(errors[Object.keys(errors)[0]])
                                    ? errors[Object.keys(errors)[0]][0]
                                    : 'Something went wrong. Please try again later'
                                await this.$confirm({ text: message, noLabel: false })
                                    .catch(() => {})
                            }
                            this.isIndexPage && this.hideErrors()
                        } else {
                            await this.$handleErrors(err)
                        }
                        this.loading = false
                    })
                    .finally(() => {
                        this.captcha = ''
                        this.loading = false
                    })
            }
        },
        localValidate () {
            let result = true
            if (this.login.name === 0 || this.login.length === 0 || this.password.length === 0) {
                result = false
                if (this.name.length === 0) {
                    this.showError('name', 'Name is required')
                }
                if (this.login.length === 0) {
                    this.showError('login', 'Email is required')
                }
                if (this.password.length === 0) {
                    this.showError('password', 'Password is required')
                }
                if (this.isIndexPage) {
                    this.hideErrors()
                }
            }
            return result
        },
        showError (name, error) {
            this.errors[name] = error
        },
        hideError (name) {
            this.errors[name] = ''
        },
        hideErrors (isNeedTimeout = true, time = 2000) {
            this.hideErrorsTimeout = setTimeout(() => {
                for (const key in this.errors) {
                    this.errors[key] = ''
                }
                this.hideErrorsTimeout = null
            }, isNeedTimeout ? time : 0)
        },
        clearHideErrorsTimeout () {
            if (this.hideErrorsTimeout) {
                clearTimeout(this.hideErrorsTimeout)
                this.hideErrors(false)
            }
        },
        verifyCaptcha (token) {
            this.captcha = token
        },
        errorCaptcha () {
            this.$alert('You have an inactive internet connection or other unexpected error. Please check your connection and try again.')
        },
        expiredCaptcha () {
            this.captcha = ''
        }
    }
})
